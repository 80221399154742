import IHttpClientDeleteParameters from '@/modules/common/contracts/http-client/http-client-delete-parameters.interface'
import IHttpClientGetParameters from '@/modules/common/contracts/http-client/http-client-get-parameters.interface'
import IHttpClientPatchParameters from '@/modules/common/contracts/http-client/http-client-patch-parameters.interface'
import IHttpClientPostParameters from '@/modules/common/contracts/http-client/http-client-post-parameters.interface'
import FileUploadService from '@/modules/common/services/file-upload.service'
import HttpClient from '@/modules/common/services/http-client.service'
import Container, { Service } from 'typedi'

@Service()
export default class RegistrationService {
  public async importFile (company_uuid: string, fileData: File, data: any) {
    if (fileData) {
      const upload = await Container.get(FileUploadService).uploadFile(fileData)
      data.upload = upload
    }
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${data.event_uuid}/import`,
      requireToken: true,
      payload: data
    }
    const response = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async updateOptionQty (option_group_uuid: string, option_uuid: string, event_uuid: string, participant_uuid: string, qty: number, removeOtherSelections: boolean) {
    const httpParams: IHttpClientPatchParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}/option/${option_group_uuid}/${option_uuid}${(removeOtherSelections ? '?removeOtherSelections=true' : '')}`,
      requireToken: true,
      payload: { qty }
    }
    const response: any = await Container.get(HttpClient).patch(httpParams)
    return response
  }

  public async getParticipantImportSample (event_uuid: string, type: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/import/sample/${type}?file_type=xlsx`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async doSearch (event_uuid: string, data: any) {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/participants/search`,
      requireToken: true,
      payload: {
        criteria: data
      }
    }
    const response: any = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async getSearches (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/participants/saved-searches`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async saveSearch (event_uuid: string, data: any) {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/participants/saved-searches`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async updateSearch (event_uuid: string, savedSearchUuid: string, data: any) {
    const httpParams: IHttpClientPatchParameters = {
      url: `/event/${event_uuid}/participants/saved-searches/${savedSearchUuid}`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).patch(httpParams)
    return response
  }

  public async getParticipantSearchResults (event_uuid: string, search_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/participants/search/${search_uuid}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async bulkApproveOrDecline (event_uuid: string, data: any, status: string) {
    const httpParams: IHttpClientPatchParameters = {
      url: `/event/${event_uuid}/participants/${status}`,
      requireToken: true,
      payload: { records: data }
    }
    const response: any = await Container.get(HttpClient).patch(httpParams)
    return response
  }

  public async getRegistrations (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/participants`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getRegistrationsSearches (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/participants/searches`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getImport (event_uuid: string, import_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/import/${import_uuid}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getPastImports (event_uuid: string, dataType: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/imports?data_type=${dataType}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getRegistrationsDeleted (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/participants/deleted`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async createRegistration (event_uuid: string, data: any) {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/participant`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async createAppCode (event_uuid: string, participant_uuid: string, data: any) {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}/app-code`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async createQualifier (event_uuid: string, participant_uuid: string, data: any) {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}/qualifier`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async updateImport (event_uuid: string, import_uuid: string, data: any) {
    const httpParams: IHttpClientPatchParameters = {
      url: `/event/${event_uuid}/import/${import_uuid}`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).patch(httpParams)
    return response
  }

  public async updateAppCode (event_uuid: string, participant_uuid: string, app_code_uuid: string, data: any) {
    const httpParams: IHttpClientPatchParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}/app-code/${app_code_uuid}`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).patch(httpParams)
    return response
  }

  public async startImport (event_uuid: string, import_uuid: string, data: any) {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/import/${import_uuid}/process`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async applyPromoCode (event_uuid: string, participant_uuid: string, data: any) {
    const httpParams: IHttpClientPatchParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}/promo-code`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).patch(httpParams)
    return response
  }

  public async removePromoCode (event_uuid: string, participant_uuid: string) {
    const httpParams: IHttpClientDeleteParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}/promo-code`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).delete(httpParams)
    return response
  }

  public async getRegistration (event_uuid: string, participant_uuid: string, addedQueryParam: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}${addedQueryParam}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getRequestLog (event_uuid: string, participant_uuid: string, request_id: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}/request-log/${request_id}`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getRegistrationAuditLog (event_uuid: string, participant_uuid: string, addedQueryParam: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}/audit-logs${addedQueryParam}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getRegistrationLeadsAppCodes (event_uuid: string, participant_uuid: string, addedQueryParam: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}/app-codes${addedQueryParam}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getRegistrationLeads (event_uuid: string, participant_uuid: string, addedQueryParam: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}/leads${addedQueryParam}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getRegistrationQualifiers (event_uuid: string, participant_uuid: string, addedQueryParam: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}/qualifiers${addedQueryParam}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getRegistrationEmailsSent (event_uuid: string, participant_uuid: string, addedQueryParam: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}/emails-sent${addedQueryParam}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getRegistrationFees (event_uuid: string, participant_uuid: string, addedQueryParam: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}/fees${addedQueryParam}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getRegistrationTransactions (event_uuid: string, participant_uuid: string, addedQueryParam: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}/transactions${addedQueryParam}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getGroupFees (event_uuid: string, group_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/group/${group_uuid}/fees`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async createGroup (event_uuid: string) {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/group`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async getRegistrationAnalytics (event_uuid: string, participant_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}/analytics`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getPreviousExternalSyncs (event_uuid: string, participant_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}/external-syncs`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getProviderData (event_uuid: string, participant_uuid: string, provider: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}/external-sync/${provider}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async approveDeclineRegistration (event_uuid: string, participant_uuid: string, data: any) {
    const httpParams: IHttpClientPatchParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}/invite-request`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).patch(httpParams)
    return response
  }

  public async editRegistration (event_uuid: string, participant_uuid: string, data: any, send_email_override?: boolean) {
    const dontPushToDB = [
      'event_id',
      'event_uuid',
      'registration_uuid',
      'deleted_at',
      'created_at',
      'updated_at',
      'modified_date',
      'create_date',
      'date_registered',
      'selected_options_kv',
      'selected_options_printable',
      'promo_code_selection',
      'promo_code_selections'
    ]

    for (const setting in data) {
      if (dontPushToDB.includes(setting)) {
        delete data[setting]
      }
    }
    const httpParams: IHttpClientPatchParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}` + (send_email_override !== undefined ? '?send_email_override=' + send_email_override : ''),
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).patch(httpParams)
    return response
  }

  public async downloadInvoice (event_uuid: string, participant_uuid: string, data: any) {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}/invoice`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async downloadSurveysResponses (event_uuid: string, participant_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}/surveys/export-all-answers`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async updateRecords (event_uuid: string, data: any) {
    const httpParams: IHttpClientPatchParameters = {
      url: `/event/${event_uuid}/participants`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).patch(httpParams)
    return response
  }

  public async deleteRegistrations (event_uuid: string, data: any) {
    const httpParams: IHttpClientDeleteParameters = {
      url: `/event/${event_uuid}/participants`,
      requireToken: true,
      config: {
        data
      }
    }
    const response: any = await Container.get(HttpClient).delete(httpParams)
    return response
  }

  public async deleteRegistration (event_uuid: string, participant_uuid: string, data: any) {
    const httpParams: IHttpClientDeleteParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}`,
      requireToken: true,
      config: {
        data
      }
    }
    const response: any = await Container.get(HttpClient).delete(httpParams)
    return response
  }

  public async getParticipantsGroup (event_uuid: string, group_uuid: string) {
    const httpParams: IHttpClientPatchParameters = {
      url: `/event/${event_uuid}/group/${group_uuid}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async updateParticipantsGroup (event_uuid: string, group_uuid, data: any) {
    const httpParams: IHttpClientPatchParameters = {
      url: `/event/${event_uuid}/group/${group_uuid}`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).patch(httpParams)
    return response
  }

  public async addParticipantToGroup (event_uuid: string, group_uuid: string, participant_uuid: string, data: any) {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/group/${group_uuid}/participant/${participant_uuid}`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async removeParticipantFromGroup (event_uuid: string, group_uuid: string, participant_uuid: string, data: any) {
    const httpParams: IHttpClientDeleteParameters = {
      url: `/event/${event_uuid}/group/${group_uuid}/participant/${participant_uuid}/membership`,
      requireToken: true,
      config: {
        data
      }
    }
    const response: any = await Container.get(HttpClient).delete(httpParams)
    return response
  }

  public async getGroups (event_uuid: string) {
    const httpParams: IHttpClientPatchParameters = {
      url: `/event/${event_uuid}/groups`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }
}
