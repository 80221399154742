import Container, { Service } from 'typedi'
import HttpClient from '@/modules/common/services/http-client.service'
import IHttpClientGetParameters from '@/modules/common/contracts/http-client/http-client-get-parameters.interface'
import IHttpClientPostParameters from '@/modules/common/contracts/http-client/http-client-post-parameters.interface'
import { AxiosResponse } from 'axios'

@Service()
export default class SurveyService {
  public async getAllContentByType (event_uuid: string, type: string, sub_type: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/content/${type}/${sub_type}/all?no_data=true`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getSurvey (event_uuid: string, type: string, sub_type: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/content/${type}/${sub_type}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getUUID (event_uuid: string, type: string, sub_type: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/content/versions/${type}/${sub_type}`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async saveSurvey (event_uuid: string, type: string, sub_type: string, data: any): Promise<any> {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/content/${type}/${sub_type}`,
      requireToken: true,
      payload: data
    }
    const response = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async getSurveyByUuid (event_uuid: string, survey_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/content/uuid/${survey_uuid}`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async fetchSurveysSummaries (event_uuid: string): Promise<AxiosResponse<unknown, any>> {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/surveys/sessions-stats`,
      requireToken: true
    }
    return await Container.get(HttpClient).get(httpParams)
  }

  public async fetchSessionsHighlightsExport (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/surveys/export-sessions-highlights`,
      requireToken: true
    }
    const response: AxiosResponse = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async fetchSurveyStats (event_uuid: string, session_uuid: string): Promise<AxiosResponse<unknown, any>> {
    let url = `/event/${event_uuid}/surveys/stats/`
    if (session_uuid === 'general' || session_uuid === 'keynote') {
      url += session_uuid
    } else {
      url += `session/${session_uuid}`
    }
    const httpParams: IHttpClientGetParameters = {
      url: url,
      requireToken: true
    }
    return await Container.get(HttpClient).get(httpParams)
  }

  public async fetchSurveysForParticipant (event_uuid: string, participant_uuid: string): Promise<AxiosResponse<unknown, any>> {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}/surveys`,
      requireToken: true
    }
    return await Container.get(HttpClient).get(httpParams)
  }

  public async getCertificates (event_uuid: string, participant_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}/survey-certificates`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getCertificateAndTranscript (event_uuid: string, participant_uuid: string, certificate_uuid: string): Promise<AxiosResponse<unknown, any>> {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}/survey-certificates/export/${certificate_uuid}`,
      requireToken: true
    }
    return await Container.get(HttpClient).get(httpParams)
  }

  public async fetchAllSurveyedParticipantsExport (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/surveys/export-all-surveyed-participants`,
      requireToken: true
    }
    const response: AxiosResponse = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async fetchAllAnswersExport (event_uuid: string, session_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/surveys/export-all-answers` + (session_uuid ? `?session_uuid=${session_uuid}` : ''),
      requireToken: true
    }
    const response: AxiosResponse = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async resetSurveyMaxAttempts (event_uuid: string, participant_uuid: string, session_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}/survey-attempts-reset/${session_uuid}`,
      requireToken: true
    }
    const response: AxiosResponse = await Container.get(HttpClient).delete(httpParams)
    return response
  }

  public async downloadPdf (event_uuid: string, session_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/surveys/stats/session/pdf/${session_uuid}`,
      requireToken: true
    }
    const response: AxiosResponse = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getEventCertificates (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/surveys/certificates`,
      requireToken: true
    }
    return await Container.get(HttpClient).get(httpParams)
  }

  public async startAllCertificatesAndTranscriptsExport (event_uuid: string, certificate_uuid: string) {
    const httpParams: IHttpClientPostParameters = {
      url: `event/${event_uuid}/surveys/export-certs/${certificate_uuid}`,
      requireToken: true
    }
    return await Container.get(HttpClient).post(httpParams)
  }

  public async getAllCertificatesAndTranscriptsExport (event_uuid: string, certificate_uuid: string): Promise<AxiosResponse<unknown, any>> {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/surveys/export-certs/${certificate_uuid}/exports`,
      requireToken: true
    }
    return await Container.get(HttpClient).get(httpParams)
  }
}
