import Vue from 'vue'
import draggable from 'vuedraggable'

Vue.component('draggable', draggable)

Vue.component(
  'error-message',
  require('@/modules/common/components/error-message/error-message.vue').default
)

Vue.component(
  'gtr-show-if',
  require('@/modules/common/components/ui-core/gtr-show-if/gtr-show-if.vue').default
)

Vue.component(
  'gtr-card',
  require('@/modules/common/components/ui-core/gtr-card/gtr-card.vue').default
)

Vue.component(
  'gtr-card-inner',
  require('@/modules/common/components/ui-core/gtr-card-inner/gtr-card-inner.vue').default
)

Vue.component(
  'gtr-page',
  require('@/modules/common/components/ui-core/gtr-page/gtr-page.vue').default
)

Vue.component(
  'gtr-loader',
  require('@/modules/common/components/ui-core/gtr-loader/gtr-loader.vue')
    .default
)

Vue.component(
  'gtr-modal',
  require('@/modules/common/components/ui-core/gtr-modal/gtr-modal.vue').default
)

Vue.component(
  'gtr-pendo',
  require('@/modules/common/components/ui-core/gtr-pendo/gtr-pendo.vue').default
)

Vue.component(
  'gtr-instruction-modal',
  require('@/modules/common/components/ui-core/gtr-instruction-modal/gtr-instruction-modal.vue').default
)

Vue.component(
  'gtr-module-widget',
  require('@/modules/common/components/ui-core/gtr-module-widget/gtr-module-widget.vue').default
)

Vue.component(
  'gtr-text-editor',
  require('@/modules/common/components/ui-core/gtr-text-editor/gtr-text-editor.vue').default
)

Vue.component(
  'gtr-password-field',
  require('@/modules/common/components/ui-core/gtr-password-field/gtr-password-field.vue').default
)

Vue.component(
  'gtr-glossary',
  require('@/modules/common/components/ui-core/gtr-glossary/gtr-glossary.vue').default
)

Vue.component(
  'gtr-confirmation',
  require('@/modules/common/components/ui-core/gtr-confirmation/gtr-confirmation.vue').default
)

Vue.component(
  'gtr-file-upload',
  require('@/modules/common/components/ui-core/gtr-file-upload/gtr-file-upload.vue').default
)

Vue.component(
  'gtr-color-picker',
  require('@/modules/common/components/ui-core/gtr-color-picker/gtr-color-picker.vue').default
)

Vue.component(
  'gtr-ace-editor',
  require('@/modules/common/components/ui-core/gtr-ace-editor/gtr-ace-editor.vue').default
)

Vue.component(
  'gtr-line-chart',
  require('@/modules/common/components/charts/line-chart/line-chart').default
)

Vue.component(
  'gtr-bar-chart',
  require('@/modules/common/components/charts/bar-chart/bar-chart').default
)

Vue.component(
  'gtr-pie-chart',
  require('@/modules/common/components/charts/pie-chart/pie-chart').default
)

Vue.component(
  'gtr-polar-area-chart',
  require('@/modules/common/components/charts/polar-area-chart/polar-area-chart').default
)

Vue.component(
  'gtr-changelog',
  require('@/modules/common/components/changelog/changelog').default
)

Vue.component(
  'gtr-helper-instructional',
  require('@/modules/common/components/helper-instructional/helper-instructional.vue').default
)

Vue.component(
  'gtr-event-correlator',
  require('@/modules/common/components/event-correlator/event-correlator.vue').default
)

Vue.component(
  'gtr-datetime-picker',
  require('@/modules/common/components/ui-core/gtr-datetime-picker/gtr-datetime-picker.vue').default
)

Vue.component(
  'gtr-date-picker',
  require('@/modules/common/components/ui-core/gtr-date-picker/gtr-date-picker.vue').default
)

Vue.component(
  'gtr-module-menu-item',
  require('@/modules/common/components/ui-core/gtr-module-menu-item/gtr-module-menu-item.vue').default
)

Vue.component(
  'gtr-event-creation-wizard',
  require('@/modules/common/components/ui-core/gtr-event-creation-wizard/gtr-event-creation-wizard.vue').default
)

Vue.component(
  'gtr-empty-state',
  require('@/modules/common/components/ui-core/gtr-empty-state/gtr-empty-state.vue').default
)

Vue.component(
  'gtr-survey-question',
  require('@/modules/common/components/ui-core/gtr-survey-question/gtr-survey-question.vue').default
)
